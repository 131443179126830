*{
  margin: 0%;
  padding: 0%;
  overflow-x: hidden;
}

.body {
  width: 100%;
  margin: 0%;
  height: 100vh;
  padding-top: 15px;
  height: auto;
  display: flex;
  background: linear-gradient(#045038, #011c0c);}
.rate-table {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: end;
  @media screen and (max-width: 992px) {
    height: auto;
    align-items: center;
  }
}
.heading {
  width: 90%;
  height: 6vh;
  background: linear-gradient(#02410c, #000000);
  display: flex;
  justify-content: space-evenly;
  border-radius: 8px;
  border: 2px solid gold;
  @media screen and (max-width: 470px) {
    font-weight: normal;
  }
}
.heading-main {
  width: 30%;
  color: gold;
  display: flex;
  font-weight: 800;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  align-items: center;
  border-right: 1px solid gold;
  justify-content: center;
  @media screen and (max-width: 470px) {
    font-size: 20px;
  }
}

.heading-box1 {
  width: 35%;
  font-weight: 800;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  color: gold;
  display: flex;
  // overflow-x: hidden;
  align-items: center;
  justify-content: center;
  border-right: 1px solid gold;
  justify-content: center;
  @media screen and (max-width: 470px) {
    font-size: 18px;
  }
}
.main-table {
  width: 90%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 2px solid gold;
}
.table-body {
  width: 100%;
  height: 10vh;
  color: white;
  display: flex;
  justify-content: center;
}
.table-box1 {
  width: 31vw;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gold;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  font-size: 28px;
  overflow: hidden;
  word-break: break-all;
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
}
.table-box2 {
  width: 13.5vw;
  height: 10vh;
  border: 1px solid gold;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 24px;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  justify-content: center;
  @media screen and (max-width: 470px)   {
    font-size: 18px;
  }
}
.table-box3 {
  width: 13.5vw;
  height: 10vh;
  background-color: goldenrod;
  display: flex;
  color: black;
  justify-content: center;
  font-size: 24px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
  border-bottom: 1px solid gold;
  align-items: center;
  @media screen and (max-width: 480px)  {
     
    font-size: 15px;
  }
}
.table-box4 {
  width: 31vw;
  height: 10vh;
  display: flex;
  align-items: center;
  font-weight: 800;
  font-size: 32px;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid gold;
  @media screen and (max-width: 1245px) {
    font-size: 26px;
    width: 100;
    height: auto;
  }
}

// section two

.rate-table2 {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    height: 80vh;
    width: 100%;
    align-items: center;
  }
}
.section2-table {
  width: 90%;
  height: 10vh;
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  // background-color: pink;
  border: 3px solid gold;
}
.section-box {
  width: 34%;
  height: 10vh;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  // background: linear-gradient(#045038, #001f0d);
  overflow: hidden;
// background-color: orange;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
  border-right: 1px solid gold;
  @media screen and (max-width:1300px) {
    font-size: 26px;    
      }
}
.section-box2 {
  width: 34%;
  height: 10vh;
  display: flex;
  color: white;
  flex-direction: column;
  overflow: hidden;
  font-size: 25px;
  background: linear-gradient(#045038, #001f0d);
  overflow: hidden;
// background-color: yellow;
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}
.bid-sell-box {
  width: 100%;
  height: 15vh;
  display: flex;
  font-size: 30px;
  font-weight: 800;
  // background-color: red;
  overflow-y: hidden;
  align-items: center;
  border-right: 1px solid gold;
  border-bottom: 1px solid gold;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  @media screen and (max-width:420px) {
font-size: 20px;    
  }
}
.gold-image {
  width: 90%;
  height: 24vh;
  border-radius: 20px;
  object-fit: fill;
  background-image: url("../assets/images/Gold_bar.jpg");
 
}




