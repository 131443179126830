.footer-div{
    // margin: 0%;
    width: 100%;
    height: 10vh;
    display: flex;
    // background-color: red;
    background: linear-gradient(#045038, #011c0c);
    align-items: center;
    border-top:5px solid gold ;
    justify-content: center;
    position:fixed;
    bottom: 0%;
}
.footer-box{
    width: 90%;
    height: auto;
    color: white;   
    display: flex;
    font-size: 20px;
    font-weight: 900;
    align-items: center;
    font-family: 'Open Sans', sans-serif;
    justify-content: space-between;
    @media screen and (max-width:883px) {
        font-size: 16px;

        
              }
}