
.header {
  width: 100%;
  // height: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 5px solid gold;
  background: linear-gradient(#045038, #011c0c);
  @media screen and (max-width: 1120px) {
    justify-content: space-evenly;
  }
}

.header-options {
  width: 100%;
  height: 16vh;
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 15px;
  font-weight: 800;
  @media screen and (max-width: 1300px) {
    display: none;
  }
}
.header-option {
  color: white;
  // padding-right: 5px;
  // border-right: 2px solid gold;

}
.header-conection {
  width: 29vw;
  display: flex;
  // background-color: violet;
  font-family: 'Open Sans', sans-serif;
  justify-content: space-evenly;
}
.header-logo {
  width: 100%;
  height: 22vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  object-fit: cover;

}
.img-responcive {
  height: auto;
  @media screen and (max-width: 690px) {
    width: 80%;
  }
}
.contry {
  width: 80%;
  height: 10vh;
  display: flex;
  // background-color: red;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1120px) {
    display: none;
  }
}
.contrys-div {
  width: 25%;
  // height: 6vh;
  border-radius: 50%;
}
.new-time {
  width: 80%;
  color: white;
  font-size: 15px;
  display: flex;
  font-weight: 800;
  font-family: 'Open Sans', sans-serif;

  justify-content: space-between;
  @media screen and (max-width: 1120px) {
    display: none;
  }
}
// .drower-menu {
//   width: 70%;
//   // border-radius: 8px;
//   // display: flex;
//   // justify-content: center;
//   // background-color: gold;
//   @media screen and (min-width: 1120px) {
//     display: none;
//   }
//   @media screen and (min-width: 768px) {
//     width: 100%;
//   }
// }

// .drower-options {
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   background-color: #02410c;
//   height: 30vh;
// }
// .under-line {
//   text-decoration: underline;
// }
